
    import Vue from 'vue';

    export default Vue.extend({
        name: 'SelectBoolean',
        model: {
            prop: 'model',
            event: 'change'
        },
        props: {
            model: {
                required: true
            },
            error: {
                default: ''
            },
            title: {
                type: String,
                default: 'Значение'
            }
        },
        data() {
            return {
                value: 'default'
            };
        },
        created() {
            if (this.model === false) {
                this.value = 'false';
            } else if (this.model === true) {
                this.value = 'true';
            }
        },
        watch: {
            value(val) {
                let returned = null;

                if (val !== 'default') {
                    returned = val === 'true';
                }

                this.$emit('change', returned);
            }
        }
    });
