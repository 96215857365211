
    import Vue from 'vue';

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default Vue.extend({
        name: 'datepicker',
        model: {
            prop: 'model',
            event: 'change'
        },
        props: {
            model: {
                required: true
            },
            title: {
                type: String,
                default: ''
            },
            error: {
                type: String,
                default: ''
            },
            desc: {
                type: String,
                default: ''
            }
        },
        created() {
            if (this.model) {
                // @ts-ignore
                const dateArr: any = this.model.split('-');

                if (dateArr.length === 3) {
                    this.date = new Date(+dateArr[0], +dateArr[1] - 1, +dateArr[2]);
                }
            }
        },
        data() {
            return {
                date: null as any,
                active: false
            };
        },
        watch: {
            date(val: any) {
                let date = '';

                if (val) {
                    date += `${val.getFullYear()}-`;
                    date += ('0' + (val.getMonth() + 1)).slice(-2) + '-';
                    date += ('0' + val.getDate()).slice(-2);
                }

                this.$emit('change', date);
            }
        },
        components: {
            DatePicker
        }
    });
