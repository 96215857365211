
    import Vue from 'vue';

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default Vue.extend({
        name: 'datetimepicker',
        model: {
            prop: 'model',
            event: 'change'
        },
        props: {
            model: {
                required: true
            },
            title: {
                type: String,
                default: ''
            },
            error: {
                type: String,
                default: ''
            },
            desc: {
                type: String,
                default: ''
            }
        },
        created() {
            if (this.model) {

                // @ts-ignore
                let model: any = +this.model;

                if (isNaN(model)) {
                    model = this.model;
                }

                // @ts-ignore
                const date: any = new Date(model);

                this.time = date;

                const onlyDate = Date.parse(date)
                    - (date.getHours() * 60 * 60 * 1000)
                    - (date.getMinutes() * 60 * 1000)
                    - (date.getSeconds() * 1000);

                this.date = new Date(onlyDate);
            }
        },
        data() {
            return {
                date: null as any,
                time: null as any,
                active: false
            };
        },
        watch: {
            date(val: any) {
                let returned: any = Date.parse(val);

                if (!val) {
                    returned = '';
                    this.time = '';
                } else {
                    if (!this.time) {
                        returned += 12 * 60 * 60 * 1000;
                        this.time = new Date(returned);
                    } else {
                        returned += (this.time.getHours() * 60 * 60 * 1000)
                            + (this.time.getMinutes() * 60 * 1000)
                            + (this.time.getSeconds() * 1000);
                    }
                }

                this.$emit('change', returned);
            },
            time(val: any) {
                if (val) {
                    if (this.date) {
                        let returned = Date.parse(this.date);

                        if (val) {
                            returned += (val.getHours() * 60 * 60 * 1000)
                                + (val.getMinutes() * 60 * 1000)
                                + (val.getSeconds() * 1000);
                        }

                        this.$emit('change', returned);
                    } else {
                        this.date = new Date();
                        this.$emit('change', Date.parse(val));
                    }
                }
            }
        },
        components: {
            DatePicker
        }
    });
